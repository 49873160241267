import React, { useEffect } from 'react'
import Footer from './Footer'
import Accordion from 'react-bootstrap/Accordion';
import { Col, Container, Row } from 'react-bootstrap'
// import { GoDotFill } from 'react-icons/go';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import NavBar from './Navbarr';

function Blogs() {
    const navigate = useNavigate();

    useEffect(() => {

        const uid = localStorage.getItem("uid");
        if (!uid) {
            navigate('/');
            return;
        }
    }, [navigate]);
    return (
        <>
            <Helmet>
                    <title>BadSkip | Blogs  </title>
                </Helmet>
                <NavBar />

            <section className="blogsSec1">
                <h2>Blogs/FAQ
                </h2>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12} className="imgmob">

                            <img src="images/top-view-person-writing-laptop-with-copy-space.png" alt="blogs" />

                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="rightfourth">
                                <h3><span>BadSkip</span> Insights –
                                    <br />
                                    Knowledge Meets
                                    <br />
                                    Experience
                                </h3>


                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>
            <section className="blogsec2">
                <h2>Popular Questions:
                </h2>
                <Container >
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is Skiptracing?</Accordion.Header>
                            <Accordion.Body>
                                Skiptracing is a specialized process designed to locate individuals who have become
                                challenging to find. By harnessing advanced data analytics, public records,
                                and proprietary search techniques, skiptracing enables businesses to reconnect with
                                elusive leads or debtors, ensuring you always have the right information at the
                                right time.

                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </Container>
            </section>
            <section className="blogsec3">
                <h2>Recent Blog Posts:
                </h2>
                <Container >
                    <ul>
                        <li>
                        {/* <GoDotFill /> */}
                            “The Art of Skiptracing: What You Need to Know”</li>
                        {/* <li><GoDotFill />
                            “Why Data Scrubbing is Essential for Your Business”</li> */}
                        <li>
                        {/* <GoDotFill /> */}
                            “Building a Remote VA Team: Best Practices</li>

                    </ul>
                    <p>
                        We believe in continuous learning and improvement, adapting to the ever-changing
                        <br />
                        landscape of technology to serve you better.
                    </p>
                </Container>
            </section>

            <Footer />
        </>
    )
}

export default Blogs
