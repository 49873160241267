import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Alert, Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import { NavHashLink } from "react-router-hash-link";
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, onSnapshot, collection } from 'firebase/firestore';
import { animateScroll as scroll } from 'react-scroll';
import { CiWarning } from "react-icons/ci";
import Swal from "sweetalert2";

function NavBar() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [showWarning, setShowWarning] = useState(false);
  const apiKey = process.env.REACT_APP_API_KEY1;
  const passKey = process.env.REACT_APP_PASS_KEY;

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const navigate = useNavigate("");


  useEffect(() => {
    const db = getFirestore();


    const systemDocRef = doc(db, "dashBoard", "system");
    const unsubscribeSystem = onSnapshot(systemDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        if (docSnapshot.data().pennyskips === false && docSnapshot.data().titanskip === false || docSnapshot.data().maintenance === true) {
          setShowWarning(true);
        } else {
          setShowWarning(false);
        }

      }
    });

    return () => {
      unsubscribeSystem();
    };
  }, []);


  const handleView = async () => {
    const url = "https://api.v2.badskip.com/user/admin-check";
    const uidAdmin = localStorage.getItem("user")
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-api-key': apiKey

        },
        body: JSON.stringify({ uid:uidAdmin, password:passKey }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.admin == true) {
          localStorage.removeItem('uid')
          localStorage.removeItem('credits')
          localStorage.removeItem('type')
          localStorage.removeItem('stripeCustomerId')
          navigate("/admin/Users")
        }
      }
    } catch (error) {
      console.error("Error fetching user details: ", error);
      Swal.fire("Error", "An unexpected error occurred", "error");
    }
  };

  return (
    <>
      {showWarning && (
        <Alert variant="warning" onClose={() => setShowWarning(false)} dismissible className="alert">
          <Alert.Heading><CiWarning /> There's currently an ongoing service maintenence. Skiptracing will be back soon.</Alert.Heading>

        </Alert>
      )}

      <Navbar expand="lg" className="bg-body-tertiary navbarr mb-3"
        style={{ top: showWarning ? '30px' : '0px' }}
      >
        <Container fluid className="d-flex justify-content-between align-items-baseline">
          <Navbar.Brand>
            <NavLink to="/" className="ImgNav">
              <img
                src="images/logo.svg"
                alt="logo"
                className="logoImg"
              />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle
            className="textttt"
            aria-controls="offcanvasNavbar"
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          />
          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={handleCloseOffcanvas}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            className="justify-content-between"
          >
            <Offcanvas.Header closeButton>

            </Offcanvas.Header>
            <Offcanvas.Body className="textttt">
              <Nav className="navgroup align-items-center w-100 justify-content-between">
                <NavLink to="/Home" onClick={handleCloseOffcanvas}>
                  Home
                </NavLink>
                <NavLink to="/about-us" onClick={handleCloseOffcanvas}>
                  About Us
                </NavLink>

                <NavHashLink smooth to="/skiptracing" onClick={handleCloseOffcanvas}>
                  Skiptracing
                </NavHashLink>
                <NavLink smooth to="/build-va-team" onClick={handleCloseOffcanvas}>
                  Build Your VA Team
                </NavLink>
                <NavLink smooth to="/Pricing" onClick={handleCloseOffcanvas}>
                  Pricing
                </NavLink>
                <NavLink smooth to="/Blogs" onClick={handleCloseOffcanvas}>
                  Blogs/FAQ
                </NavLink>


                <button className="signbtn" onClick={() => handleView()}>Back to Dashboard</button>

              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
