import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table, Pagination } from 'react-bootstrap';
import { FiUpload } from 'react-icons/fi';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { app } from './firebase';

const Preloader = () => (
    <div className="loader"></div>
);
const db = getFirestore(app);

function Optimizer() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [rowsPerPage] = useState(10);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const apiKey = process.env.REACT_APP_API_KEY1;
    const [showWarning, setShowWarning] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [credits, setCredits] = useState(0);
    const [headers, setHeaders] = useState([]);
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('uid') || ' ';
    const fileId = urlParams.get('fileId') || ' ';
    const findClosestColumn = (labels, columns) => {
        const regex = new RegExp(`\\b(${labels.join('|')})\\b`, 'i');
        const matchedColumn = columns.find(col =>
            regex.test(col.toLowerCase()) && !col.toLowerCase().includes('mailing')
        );

        return matchedColumn ? matchedColumn : "";
    };
    const findClosestColumnRest = (label, columns) => {
        const matchedColumn = columns.find(col => col.toLowerCase().includes(label.toLowerCase()));

        return matchedColumn ? matchedColumn : "";
    };

    useEffect(() => {
      
        if (uid.length > 0 && fileId > 0) {
            const formData = new FormData();
            formData.append('uid', uid);
            formData.append('fileId', fileId);
            const handleSubmission = async () => {
                try {
                
                    const response = await axios.post("https://api.v2.badskip.com/skiptracing/optimize", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'x-api-key': apiKey
                        }
                    });
                    if (response.status === 200) {
                        Swal.fire({
                            title: 'Confirmation',
                            text: 'Do you want to proceed?',
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/admin/Files');
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Failed to send data.',
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        showCancelButton: true,
                        text: 'An error occurred during submission.',
                    });
                }
            };
            handleSubmission();

        }


    }, [navigate]);
    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userDocRef = doc(db, 'userProfiles', currentUser.uid);
                const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setCredits(docSnapshot.data().credits || 0);
                    }
                });

                return () => unsubscribeSnapshot();
            } else {
                setUser(null);
            }
        });

        if (tableData.length > 0) {
            const columns = Object.keys(tableData[0]);
            setData({
                owner1FirstName: findClosestColumnRest('first', columns),
                owner1LastName: findClosestColumnRest('last', columns),
                owner2FirstName: findClosestColumnRest('owner 2 first name', columns),
                owner2LastName: findClosestColumnRest('owner 2 last name', columns),
                street: findClosestColumn(['address', 'property address'], columns),
                city: findClosestColumn(['city', 'property city'], columns),
                state: findClosestColumn(['state', 'property state'], columns),
                zip: findClosestColumn(['zip', 'zip code', 'property zip code', 'zip_code'], columns),
                altStreet: findClosestColumnRest('mailing address', columns),
                altCity: findClosestColumnRest('mailing city', columns),
                altState: findClosestColumnRest('mailing state', columns),
                altZip: findClosestColumnRest('mailing zip', columns),
            });
        }
        return () => unsubscribeAuth();

    }, [tableData]);



    const preprocessData = (results) => {
        let tempHeaders = results.meta.fields;
        let tempData = results.data;

        const ownerFullNamePattern = /^Owner \d+ Full Name$/;
        const newHeaders = tempHeaders.filter(header => !ownerFullNamePattern.test(header));

        const updatedData = tempData.map(row => {
            const newRow = { ...row };
            const ownerNames = [];

            tempHeaders.forEach(header => {
                if (ownerFullNamePattern.test(header)) {
                    const ownerNumber = header.match(/\d+/)[0];
                    const fullName = row[header] || '';
                    const nameParts = fullName.split(' ');
                    const firstName = nameParts[0] || '';
                    const lastName = nameParts.length > 1 ? nameParts.slice(-1).join(' ') : '';

                    if (fullName) ownerNames.push({ firstName, lastName, number: ownerNumber });
                    newRow[`Owner ${ownerNumber} First Name`] = newRow[`Owner ${ownerNumber} First Name`] || firstName;
                    newRow[`Owner ${ownerNumber} Last Name`] = newRow[`Owner ${ownerNumber} Last Name`] || lastName;

                    if (!newHeaders.includes(`Owner ${ownerNumber} First Name`)) newHeaders.push(`Owner ${ownerNumber} First Name`);
                    if (!newHeaders.includes(`Owner ${ownerNumber} Last Name`)) newHeaders.push(`Owner ${ownerNumber} Last Name`);
                    delete newRow[header];
                }
            });

            for (let i = 1; i <= 4; i++) {
                const owner = ownerNames.shift();
                if (owner) {
                    newRow[`Owner ${i} First Name`] = owner.firstName;
                    newRow[`Owner ${i} Last Name`] = owner.lastName;
                } else {
                    newRow[`Owner ${i} First Name`] = newRow[`Owner ${i} First Name`] || '';
                    newRow[`Owner ${i} Last Name`] = newRow[`Owner ${i} Last Name`] || '';
                }
            }

            return newRow;
        });

        // Filter out rows based on your conditions
        const filteredData = updatedData.filter(row => !shouldRemoveRow(row));
        const filteredData2 = filteredData.filter(row => !shouldRemoveRowAddress(row));

        setHeaders(newHeaders);
        setTableData(filteredData2);
        return filteredData2;


    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file)
        if (file) {
            Papa.parse(file, {
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: (result) => {
                    const preprocessedData = preprocessData(result);
                    const filteredDataCount = preprocessedData.length;
                    console.log("Filtered Data Count from Preprocessing:", filteredDataCount);

                    const nonEmptyRows = preprocessedData.filter(row =>
                        Object.values(row).some(value => value !== null && value !== "")
                    );
                    console.log("Non-Empty Rows Count:", nonEmptyRows.length);

                    const uniqueRows = Array.from(new Set(nonEmptyRows.map(row => JSON.stringify(row))))
                        .map(row => JSON.parse(row));
                    console.log("Unique Rows Count:", uniqueRows.length);

                    const filteredAndValidData = uniqueRows.filter(row =>
                        Object.values(row).some(value => value !== null && value !== "") &&
                        !shouldRemoveRow(row)
                    );
                    console.log("Filtered and Valid Data Count:", filteredAndValidData.length);


                    const sanitizedData = filteredAndValidData.map(row => {
                        const sanitizedRow = {};
                        for (const key in row) {
                            if (row.hasOwnProperty(key)) {
                                sanitizedRow[key] = typeof row[key] === 'string' ? row[key].trim() : row[key];
                            }
                        }
                        return sanitizedRow;
                    });

                    if (sanitizedData.length > 27500) {
                        Swal.fire({
                            icon: 'error',
                            title: 'File too large',
                            text: 'The file contains more than 27,500 records.',
                        });
                        setTableData([]);
                    } else {
                        setTableData(sanitizedData);
                        setRowCount(sanitizedData.length);
                        const csvData = createCSV(sanitizedData);
                    }
                }
            });
        }
    };

    const shouldRemoveRow = (row) => {
        const keywordsToCheck = ["llc", "company", "trust", "inc", "ltd", "lp", "contractors", 'association', 'township', "invest", "partnership", "corporation", "incorporated", "restorations", 'partners', 'neighborhood'];
        const includedColumns = ["first", "last", "name", "one", "two", "1", "2"];

        function isIncludedColumn(columnName) {
            return includedColumns.some(includedKeyword => columnName.toLowerCase().includes(includedKeyword));
        }

        return Object.keys(row).some(columnName => {
            const cellValue = row[columnName];
            return isIncludedColumn(columnName) && typeof cellValue === 'string' &&
                keywordsToCheck.some(keyword => cellValue.toLowerCase().includes(keyword.toLowerCase()));
        });
    };

    const shouldRemoveRowAddress = (row) => {
        const keywordsToCheck = ['apt', 'unit', '#'];
        const includedColumns = ["address"];

        function isIncludedColumn(columnName) {
            return includedColumns.some(includedKeyword => columnName.toLowerCase().includes(includedKeyword));
        }

        return Object.keys(row).some(columnName => {
            const cellValue = row[columnName];
            return isIncludedColumn(columnName) && typeof cellValue === 'string' &&
                keywordsToCheck.some(keyword => cellValue.toLowerCase().includes(keyword.toLowerCase()));
        });
    };
    const createCSV = (data) => {
        if (data.length === 0) return '';

        const headers = Object.keys(data[0]);
        const csvRows = [headers.join(',')];

        data.forEach(row => {
            const values = headers.map(header => {
                let value = row[header] !== undefined ? row[header] : '';
                value = (typeof value === 'string' || value instanceof String) ? value.replace(/"/g, '""') : value;
                return `"${value}"`;
            });
            csvRows.push(values.join(','));
        });

        return csvRows.join('\n');
    };

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(tableData.length / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [data, setData] = useState({
        owner1FirstName: "",
        owner1LastName: "",
        owner2FirstName: "",
        owner2LastName: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        altStreet: "",
        altCity: "",
        altState: "",
        altZip: "",
    });

    useEffect(() => {
        const uid = localStorage.getItem("user");
        if (!uid) {
            navigate('/admin');
        }
        const systemDocRef = doc(db, "dashBoard", "system");
        const unsubscribeSystem = onSnapshot(systemDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                if (docSnapshot.data().pennyskips === false && docSnapshot.data().titanskip === false || docSnapshot.data().maintenance === true) {
                    setShowWarning(true);
                } else {
                    setShowWarning(false);
                }
            }
        });
        return () => {
            unsubscribeSystem();
        };
    }, [navigate]);

    const onInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (tableData.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'No Data',
                text: 'There is no data to submit.',
                customClass: {
                    confirmButton: 'my-confirm-button',
                }

            });
            return;
        }
        setIsSubmitting(true);

        const formData = new FormData();
        const csvData = createCSV(tableData);

        const blob = new Blob([csvData], { type: 'text/csv' });
        const fileName = selectedFile ? selectedFile.name.replace(/\.[^/.]+$/, "") + ".csv" : "badskip.csv";
        // formData.append('file', blob, fileName);

        formData.append('file', selectedFile);
        // formData.append('uid', '');
        // formData.append('fileId', '');

        try {
            const response = await axios.post("https://api.v2.badskip.com/skiptracing/optimize", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-api-key': apiKey
                }
            });

            if (response.status == 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Processing',
                    text: "File successfully processed.",
                    timer: 1000,
                    customClass: {
                        confirmButton: 'my-confirm-button',
                    }
                }).then(() => navigate("/admin/ListHistory"));
                setIsSubmitting(false);
                // console.log(response);

            } else {
                setIsSubmitting(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Something went wrong',
                    text: 'Please try again.',
                    customClass: {
                        confirmButton: 'my-confirm-button',
                    }
                });
            }
        } catch (error) {
            setIsSubmitting(false);
            if (error.response.status === 503) {

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something went wrong',
                    text: error.response?.data?.message || 'An error occurred. Please try again.',
                    customClass: {
                        confirmButton: 'my-confirm-button',
                    }
                });
            }
        }

    };

    const renderPagination = () => {
        const pageNumbers = [];
        const maxPagesToShow = 8;
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (endPage - startPage < maxPagesToShow - 1) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        return (
            <Pagination>
                <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
                {pageNumbers}
                <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
            </Pagination>
        );
    };

    useEffect(() => {
        const bootstrapLink = document.createElement('link');
        bootstrapLink.rel = 'stylesheet';
        bootstrapLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
        document.head.appendChild(bootstrapLink);

        return () => {
            document.head.removeChild(bootstrapLink);
        };

    }, []);
    return (
        <div className='uploadnew'>

            <section className='listUpload'>
                <form method="post" onSubmit={(e) => onSubmit(e)} encType='multipart/form-data'>
                    <Container>
                        <Row className='justify-content-between px-5'>
                            <Col md={4} sm={4}>
                                <div className='list'>
                                    <img src="../images/liost.svg" alt="upload-list" />
                                    <h3>Optimzer</h3>
                                </div>
                            </Col>
                            <Col md={3} sm={3} className='uploaddiv'>
                                <div className='uploadInput'>
                                    <input
                                        type="file"
                                        name='file'
                                        id="fileInput"
                                        disabled={showWarning}
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                        accept='.csv'
                                    />
                                    <label htmlFor="fileInput" className="custom-button">

                                        <FiUpload />
                                        Upload

                                    </label>
                                </div>
                                <p>Please Make Sure to Upload a CSV File</p>
                            </Col>
                        </Row>

                        {/* Display Table if CSV is uploaded */}
                        {selectedFile && tableData.length > 0 && (
                            <Row className='my-5 tableShowList'>
                                <Col md={9} sm={12}>
                                    <div className='table-responsive'>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    {Object.keys(currentRows[0] || {}).map((col, index) => (
                                                        <th key={index}>{col}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentRows.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {Object.values(row).map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                    </div>
                                    {renderPagination()}

                                </Col>
                                <Col md={3} sm={12}>
                                    <div className='categoryl'>
                                        <div className="d-flex justify-content-between my-2">
                                            <h5>
                                                Records

                                            </h5>
                                            <p>
                                                {rowCount}

                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between my-2">
                                            <h5>
                                                Cost

                                            </h5>
                                            <p>
                                                {rowCount} Credits

                                            </p>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        )}

                        {!selectedFile ?
                            <>
                                <div className='uploadDiv'>
                                    <img src="../images/Group(5).png" alt="upload-list" />
                                    <h3>No List Uploaded</h3>
                                    <p>Upload a List to Start The Process</p>
                                </div>

                            </>
                            : <>
                                <div className='listbtn'>
                                    <button type="submit" disabled={isSubmitting || tableData.length === 0}>  {isSubmitting ? <Preloader /> : "Start"}</button>

                                </div>
                            </>}


                    </Container>
                </form>
            </section>

        </div>
    );
}

export default Optimizer;
